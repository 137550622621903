import 'bootstrap'
import './sass/main.scss'

let vollversion = true;
let timeout;
let questionID = 0;
let questionCount = 29;

let hundefueherValueA = 0;
let hundefueherValueB = 0;
let hundefueherValueC = 0;
let hundefueherValueD = 0;

let hundetypValueA = 0;
let hundetypValueB = 0;
let hundetypValueC = 0;
let hundetypValueD = 0;

let resultFuehrerArray = [
    'Chef',
    'Partner',
    'Trainer',
    'Förderer'];
let resultFuehrerTextArray = [
    'Du neigst in deinem typischen Verhalten zum Chef, der nach realistischen gemeinsamen Aufgaben mit seinem Hund sucht. Du verlässt dich dabei auf deine Erfahrung, hältst dich an bewährte Regeln und bewertest besonnen und methodisch.',
    'Du neigst in deinem typischen Verhalten zum Partner, der nach der größtmöglichen Nähe zu seinem Hund sucht. Du verlässt dich dabei auf deine Erfahrung, vertraust deinen persönlichen Gefühlen und bewertest besonnen und einfühlsam.',
    'Du neigst in deinem typischen Verhalten zum Trainer, der sich gerne mit anderen misst und nach Wettbewerben mit und für seinen Hund sucht. Du verlässt dich dabei auf deine Fantasie vertraust allgemeingültigen und bewährten Regeln, und bewertest intuitiv und methodisch.',
    'Du neigst in deinem typischen Verhalten zum Förderer, der sich mit seinem Hund stetig weiterentwickeln will. Du verlässt dich dabei auf deine Fantasie, vertraust deinen persönlichen Gefühlen und bewertest intuitiv und einfühlsam.'];
let resultFuehrerNumber;
let resultFuehrerString;

let resultTypArray = [
    'Einfarbig Blauer Hund',
    'Blauer Hund mit Grün',
    'Blauer Hund mit Gelb',
    'Blauer Bunter Hund',

    'Grüner Hund mit Blau',
    'Einfarbig Grüner Hund',
    'Grüner Bunter Hund',
    'Grüner Hund mit Rot',

    'Gelber Hund mit Blau',
    'Gelber Bunter Hund',
    'Einfarbig Gelber Hund',
    'Gelber Hund mit Rot',

    'Roter Bunter Hund',
    'Roter Hund mit Grün',
    'Roter Hund mit Gelb ',
    'Einfarbig Roter Hund']

let resultTypTextArray = [
    'Du hast einen Einfarbig Blauen Hund, er ist defensiv und autonom, hat wenig Temperament und drängt sich nicht auf. Er kann seine Impulse gut kontrollieren und ist sehr schwer reizbar. Er neigt zu übersteigert defensivem Verhalten, zu übersteigertem Distanzverhalten und zu übersteigertem Isolationsverhalten. Gerät er unter Stress, vereinsamt er, isoliert sich, und wird wechselweise aufsässig und träge.',
    'Du hast einen Blauen Hund mit Grün, er ist defensiv und moderat autonom, hat wenig Temperament und drängt sich nur wenig auf. Er kann seine Impulse gut kontrollieren und ist schwer reizbar. Er neigt zu übersteigert defensivem Verhalten. Gerät er unter Stress, vereinsamt er und wird ängstlich und träge.',
    'Du hast einen Blauen Hund mit Gelb, er ist autonom und moderat defensiv, drängt sich nicht auf und hat ein mittleres Temperament. Er kann seine Impulse nicht immer kontrollieren, ist jedoch sehr schwer reizbar. Er neigt zu übersteigertem Distanzverhalten. Gerät er unter Stress, vereinsamt er, isoliert sich und verhält sich aufsässig.',
    'Du hast einen Blauen Bunten Hund, er ist moderat defensiv und moderat autonom, er drängt sich nicht auf und hat ein mittleres Temperament. Er kann seine Impulse kontrollieren und sich gut anpassen. Er ist zuverlässig, geduldig und unaufdringlich und verhält sich zurückhaltend und eigenständig. Er läßt sich leicht führen und ist nicht anfällig für Stress, weil seine Bedürfnisse und Ängste in einem harmonischen Verhältnis stehen.',

    'Du hast einen Grünen Hund mit Blau, er ist defensiv und moderat anhänglich, hat wenig Temperament und braucht Nähe. Er kann seine Impulse gut kontrollieren, ist jedoch leicht reizbar. Er neigt zu übersteigert defensivem Verhalten. Gerät er unter Stress, unterwirft er sich und wird ängstlich und träge.',
    'Du hast einen Einfarbig Grünen Hund, er ist defensiv und anhänglich, hat wenig Temperament und braucht viel Nähe. Er kann seine Impulse gut kontrollieren, ist jedoch sehr leicht reizbar. Er neigt zu übersteigert defensivem Verhalten, zu übersteigert anhänglichem Verhalten und zu übersteigert unterwerfendem Verhalten. Gerät er unter Stress, verhält er sich devot, biedert sich an, und wird ängstlich und träge.',
    'Du hast einen Grünen Bunter Hund, er ist moderat anhänglich und moderat defensiv, braucht Nähe und hat ein mittleres Temperament. Er kann seine Impulse kontrollieren und sich gut anpassen. Er ist gutmütig, geduldig und empfindsam und verhält sich zurückhaltend und beharrlich. Er läßt sich leicht führen und ist nicht anfällig für Stress, weil seine Bedürfnisse und Ängste in einem harmonischem Verhältnis stehen.',
    'Du hast einen Grünen Hund mit Rot, er ist anhänglich und moderat defensiv, braucht viel Nähe und hat ein mittleres Temperament. Er kann seine Impulse nicht immer kontrollieren und ist sehr leicht reizbar. Er neigt zu übersteigert anhänglichem Verhalten. Gerät er unter Stress, unterwirft er sich, biedert sich an und verhält sich devot.',

    'Du hast einen Gelben Hund mit Blau, er ist autonom und moderat offensiv, hat ein ausgeglichenes Temperament und drängt sich nicht auf. Er kann seine Impulse selten kontrollieren, ist jedoch sehr schwer reizbar. Er neigt zu übersteigertem Distanzverhalten. Gerät er unter Stress, neigt er zur Verweigerung. Er isoliert sich und wird misstrauisch und aufsässig.',
    'Du hast einen Gelben Bunter Hund, er ist moderat offensiv und moderat autonom, hat ein mittleres Temperament und drängt sich nicht auf. Er kann seine Impulse kontrollieren und sich gut anpassen. Er ist unbeschwert, munter und unaufdringlich und verhält sich offen und eigenständig. Er läßt sich leicht führen und ist nicht anfällig für Stress, weil seine Bedürfnisse und Ängste in einem harmonischem Verhältnis stehen.',
    'Du hast einen Einfarbig Gelben Hund, er ist offensiv und autonom, hat ein großes Temperament und drängt sich nicht auf. Er kann seine Impulse nicht kontrollieren, ist jedoch sehr schwer reizbar. Er neigt zu übersteigert offensivem Verhalten, zu übersteigertem Distanzverhalten und zu übersteigert verweigerndem Verhalten. Gerät er unter Stress, entzieht er sich, wird leichtsinnig und verhält sich aufsässig und übermütig.',
    'Du hast einen Gelben Hund mit Rot, er ist offensiv und moderat autonom, hat ein großes Temperament und drängt sich nicht auf. Er kann seine Impulse nicht kontrollieren, ist jedoch schwer reizbar. Er neigt zu übersteigert offensivem Verhalten. Gerät er unter Stress, neigt er zur Verweigerung. Er wird leichtsinnig und verhält sich übermütig.',

    'Du hast einen Roten Bunter Hund, er ist moderat offensiv und moderat anhänglich, hat ein mittleres Temperament und braucht Nähe. Er kann seine Impulse kontrollieren und sich gut anpassen. Er ist vertrauensvoll, munter und empfindsam und verhält sich offen und zugewandt. Er läßt sich leicht führen und ist nicht anfällig für Stress, weil seine Bedürfnisse und Ängste in einem harmonischem Verhältnis stehen.',
    'Du hast eine Roten Hund mit Grün, er ist anhänglich und moderat offensiv, braucht viel Nähe und hat ein mittleres Temperament. Er kann seine Impulse selten kontrollieren und ist sehr leicht reizbar. Er neigt zu übersteigert anhänglichem Verhalten. Gerät er unter Stress, wird er belästigend und biedert sich an.',
    'Du hast einen Roten Hund mit Gelb, er ist offensiv und moderat anhänglich, hat ein großes Temperament und braucht Nähe. Er kann seine Impulse nicht kontrollieren und ist leicht reizbar. Er neigt zu übersteigert offensivem Verhalten. Gerät er unter Stress, wird er leichtsinnig und übermütig.',
    'Du hast einen Einfarbig Roten Hund, er ist offensiv und anhänglich, hat ein großes Temperament und braucht viel Nähe. Er kann seine Impulse nicht kontrollieren und ist sehr leicht reizbar. Er neigt zu übersteigert offensivem Verhalten, zu übersteigert anhänglichem Verhalten und zu übersteigert belästigendem Verhalten. Gerät er unter Stress, drängt er sich auf, biedert sich an und verhält sich leichtsinnig und übermütig.']
let resultTypNumber = '';

let resultLongVersionPdf = [
    // 1_Blaue_Hunde
    ['1.1.1_Chef_Einfarbig_Blauer_Hund.pdf',        '1.1.2_Partner_Einfarbig_Blauer_Hund.pdf',      '1.1.4_Trainer_Einfarbig_Blauer_Hund.pdf',      '1.1.3_Foerderer_Einfarbig_Blauer_Hund.pdf'],
    ['1.3.1_Chef_mit_Blauer_Hund_mit_Gruen.pdf',    '1.3.2_Partner_mit_Blauer_Hund_mit_Gruen.pdf',  '1.3.4_Trainer_mit_Blauer_Hund_mit_Gruen.pdf',  '1.3.3_Foerderer_mit_Blauer_Hund_mit_Gruen.pdf'],
    ['1.2.1_Chef_mit_Blauer_Hund_mit_Gelb.pdf',     '1.2.2_Partner_mit_Blauer_Hund_mit_Gelb.pdf',   '1.2.4_Trainer_mit_Blauer_Hund_mit_Gelb.pdf',   '1.2.3_Foerderer_mit_Blauer_Hund_mit_Gelb.pdf'],
    ['1.4.1_Chef_mit_Blauer_Bunter_Hund.pdf',       '1.4.2_Partner_mit_Blauer_Bunter_Hund.pdf',     '1.4.4_Trainer_mit_Blauer_Bunter_Hund.pdf',     '1.4.3_Foerderer_mit_Blauer_Bunter_Hund.pdf'],

    // 2_Gruene_Hunde
    ['2.2.1_Chef_mit_Gruener_Hund_mit_Blau.pdf',    '2.2.2_Partner_mit_Gruener_Hund_mit_Blau.pdf',  '2.2.4_Trainer_mit_Gruener_Hund_mit_Blau.pdf',  '2.2.3_Foerderer_mit_Gruener_Hund_mit_Blau.pdf'],
    ['2.1.1_Chef_Einfarbig_Gruener_Hund.pdf',       '2.1.2_Partner_Einfarbig_Gruener_Hund.pdf',     '2.1.4_Trainer_Einfarbig_Gruener_Hund.pdf',     '2.1.3_Foerderer_Einfarbig_Gruener_Hund.pdf'],
    ['2.4.1_Chef_mit_Gruener_Bunter_Hund.pdf',      '2.4.2_Partner_mit_Gruener_Bunter_Hund.pdf',    '2.4.4_Trainer_mit_Gruener_Bunter_Hund.pdf',    '2.4.3_Foerderer_mit_Gruener_Bunter_Hund.pdf'],
    ['2.3.1_Chef_mit_Gruener_Hund_mit_Rot.pdf',     '2.3.2_Partner_mit_Gruener_Hund_mit_Rot.pdf',   '2.3.4_Trainer_mit_Gruener_Hund_mit_Rot.pdf',   '2.3.3_Foerderer_mit_Gruener_Hund_mit_Rot.pdf'],

    // 4_Gelbe_Hunde
    ['4.2.1_Chef_Gelber_Hund_mit_Blau.pdf',         '4.2.2_Partner_Gelber_Hund_mit_Blau.pdf',       '4.2.4_Trainer_Gelber_Hund_mit_Blau.pdf',       '4.2.3_Foerderer_Gelber_Hund_mit_Blau.pdf'],
    ['4.4.1_Chef_mit_Gelber_Bunter_Hund.pdf',       '4.4.2_Partner_mit_Gelber_Bunter_Hund.pdf',     '4.4.4_Trainer_mit_Gelber_Bunter_Hund.pdf',     '4.4.3_Foerderer_mit_Gelber_Bunter_Hund.pdf'],
    ['4.1.1_Chef_Einfarbig_Gelber_Hund.pdf',        '4.1.2_Partner_Einfarbig_Gelber_Hund.pdf',      '4.1.4_Trainer_Einfarbig_Gelber_Hund.pdf',      '4.1.3_Foerderer_Einfarbig_Gelber_Hund.pdf'],
    ['4.3.1_Chef_Gelber_Hund_mit_Rot.pdf',          '4.3.2_Partner_Gelber_Hund_mit_Rot.pdf',        '4.3.3_Foerderer_Gelber_Hund_mit_Rot.pdf',      '4.3.4_Trainer_Gelber_Hund_mit_Rot.pdf'],

    // 3_Rote_Hunde
    ['3.4.1_Chef_mit_Roter_Bunter_Hund.pdf',        '3.4.2_Partner_mit_Roter_Bunter_Hund.pdf',      '3.4.4_Trainer_mit_Roter_Bunter_Hund.pdf',      '3.4.3_Foerderer_mit_Roter_Bunter_Hund.pdf'],
    ['3.3.1_Chef_Roter_Hund_mit_Gruen.pdf',         '3.3.2_Partner_Roter_Hund_mit_Gruen.pdf',       '3.3.4_Trainer_Roter_Hund_mit_Gruen.pdf',       '3.3.3_Foerderer_Roter_Hund_mit_Gruen.pdf'],
    ['3.2.1_Chef_Roter_Hund_mit_Gelb.pdf',          '3.2.2_Partner_Roter_Hund_mit_Gelb.pdf',        '3.2.4_Trainer_Roter_Hund_mit_Gelb.pdf',        '3.2.3_Foerderer_Roter_Hund_mit_Gelb.pdf'],
    ['3.1.1_Chef_Einfarbig_Roter_Hund.pdf',         '3.1.2_Partner_Einfarbig_Roter_Hund.pdf',       '3.1.4_Trainer_Einfarbig_Roter_Hund.pdf',       '3.1.3_Foerderer_Einfarbig_Roter_Hund.pdf']
];

let resultShortVersionPdf = [
    // 1_Blaue_Hunde
    ['1.1.1_Chef_Einfarbig_Blauer_Hund_Kurz.pdf',        '1.1.2_Partner_Einfarbig_Blauer_Hund_Kurz.pdf',      '1.1.4_Trainer_Einfarbig_Blauer_Hund_Kurz.pdf',      '1.1.3_Foerderer_Einfarbig_Blauer_Hund_Kurz.pdf'],
    ['1.3.1_Chef_mit_Blauer_Hund_mit_Gruen_Kurz.pdf',    '1.3.2_Partner_mit_Blauer_Hund_mit_Gruen_Kurz.pdf',  '1.3.4_Trainer_mit_Blauer_Hund_mit_Gruen_Kurz.pdf',  '1.3.3_Foerderer_mit_Blauer_Hund_mit_Gruen_Kurz.pdf'],
    ['1.2.1_Chef_mit_Blauer_Hund_mit_Gelb_Kurz.pdf',     '1.2.2_Partner_mit_Blauer_Hund_mit_Gelb_Kurz.pdf',   '1.2.4_Trainer_mit_Blauer_Hund_mit_Gelb_Kurz.pdf',   '1.2.3_Foerderer_mit_Blauer_Hund_mit_Gelb_Kurz.pdf'],
    ['1.4.1_Chef_mit_Blauer_Bunter_Hund_Kurz.pdf',       '1.4.2_Partner_mit_Blauer_Bunter_Hund_Kurz.pdf',     '1.4.4_Trainer_mit_Blauer_Bunter_Hund_Kurz.pdf',     '1.4.3_Foerderer_mit_Blauer_Bunter_Hund_Kurz.pdf'],

    // 2_Gruene_Hunde
    ['2.2.1_Chef_mit_Gruener_Hund_mit_Blau_Kurz.pdf',    '2.2.2_Partner_mit_Gruener_Hund_mit_Blau_Kurz.pdf',  '2.2.4_Trainer_mit_Gruener_Hund_mit_Blau_Kurz.pdf',  '2.2.3_Foerderer_mit_Gruener_Hund_mit_Blau_Kurz.pdf'],
    ['2.1.1_Chef_Einfarbig_Gruener_Hund_Kurz.pdf',       '2.1.2_Partner_Einfarbig_Gruener_Hund_Kurz.pdf',     '2.1.4_Trainer_Einfarbig_Gruener_Hund_Kurz.pdf',     '2.1.3_Foerderer_Einfarbig_Gruener_Hund_Kurz.pdf'],
    ['2.4.1_Chef_mit_Gruener_Bunter_Hund_Kurz.pdf',      '2.4.2_Partner_mit_Gruener_Bunter_Hund_Kurz.pdf',    '2.4.4_Trainer_mit_Gruener_Bunter_Hund_Kurz.pdf',    '2.4.3_Foerderer_mit_Gruener_Bunter_Hund_Kurz.pdf'],
    ['2.3.1_Chef_mit_Gruener_Hund_mit_Rot_Kurz.pdf',     '2.3.2_Partner_mit_Gruener_Hund_mit_Rot_Kurz.pdf',   '2.3.4_Trainer_mit_Gruener_Hund_mit_Rot_Kurz.pdf',   '2.3.3_Foerderer_mit_Gruener_Hund_mit_Rot_Kurz.pdf'],

    // 4_Gelbe_Hunde
    ['4.2.1_Chef_Gelber_Hund_mit_Blau_Kurz.pdf',         '4.2.2_Partner_Gelber_Hund_mit_Blau_Kurz.pdf',       '4.2.4_Trainer_Gelber_Hund_mit_Blau_Kurz.pdf',       '4.2.3_Foerderer_Gelber_Hund_mit_Blau_Kurz.pdf'],
    ['4.4.1_Chef_mit_Gelber_Bunter_Hund_Kurz.pdf',       '4.4.2_Partner_mit_Gelber_Bunter_Hund_Kurz.pdf',     '4.4.4_Trainer_mit_Gelber_Bunter_Hund_Kurz.pdf',     '4.4.3_Foerderer_mit_Gelber_Bunter_Hund_Kurz.pdf'],
    ['4.1.1_Chef_Einfarbig_Gelber_Hund_Kurz.pdf',        '4.1.2_Partner_Einfarbig_Gelber_Hund_Kurz.pdf',      '4.1.4_Trainer_Einfarbig_Gelber_Hund_Kurz.pdf',      '4.1.3_Foerderer_Einfarbig_Gelber_Hund_Kurz.pdf'],
    ['4.3.1_Chef_Gelber_Hund_mit_Rot_Kurz.pdf',          '4.3.2_Partner_Gelber_Hund_mit_Rot_Kurz.pdf',        '4.3.3_Foerderer_Gelber_Hund_mit_Rot_Kurz.pdf',      '4.3.4_Trainer_Gelber_Hund_mit_Rot_Kurz.pdf'],

    // 3_Rote_Hunde
    ['3.4.1_Chef_mit_Roter_Bunter_Hund_Kurz.pdf',        '3.4.2_Partner_mit_Roter_Bunter_Hund_Kurz.pdf',      '3.4.4_Trainer_mit_Roter_Bunter_Hund_Kurz.pdf',      '3.4.3_Foerderer_mit_Roter_Bunter_Hund_Kurz.pdf'],
    ['3.3.1_Chef_Roter_Hund_mit_Gruen_Kurz.pdf',         '3.3.2_Partner_Roter_Hund_mit_Gruen_Kurz.pdf',       '3.3.4_Trainer_Roter_Hund_mit_Gruen_Kurz.pdf',       '3.3.3_Foerderer_Roter_Hund_mit_Grue_Kurzn.pdf'],
    ['3.2.1_Chef_Roter_Hund_mit_Gelb_Kurz.pdf',          '3.2.2_Partner_Roter_Hund_mit_Gelb_Kurz.pdf',        '3.2.4_Trainer_Roter_Hund_mit_Gelb_Kurz.pdf',        '3.2.3_Foerderer_Roter_Hund_mit_Gelb_Kurz.pdf'],
    ['3.1.1_Chef_Einfarbig_Roter_Hund_Kurz.pdf',         '3.1.2_Partner_Einfarbig_Roter_Hund_Kurz.pdf',       '3.1.4_Trainer_Einfarbig_Roter_Hund_Kurz.pdf',       '3.1.3_Foerderer_Einfarbig_Roter_Hund_Kurz.pdf']
];

(function (  )
{
    'use strict';

    $(document).ready(function()
    {
        // preloader
        $('#preloader').fadeOut(1000,function(){ });

        initListener();

        //showQuestionById(0);

        function initListener()
        {
            $('.input_code').val('');

            $('.passwort_button').click(function()
            {
                if( $('.input_code').val() === '24242')
                {
                    $('.login_section').removeClass("show");
                    showQuestionById(0);
                }
                else {
                    $(".form_feedback").text('Bitte überprüfen Sie Ihre Eingaben!');
                }
            });

            $(".answer").click(function()
            {
               if( $(this).data('is-question') === false) {
                   return;
               }

               // Hundefueher Fragen
                let hundefueherQuestionId = questionID-2;
                if(hundefueherQuestionId > 0 && hundefueherQuestionId < 15) {
                    hundefueherValueA += $(this).data("a");
                    hundefueherValueB += $(this).data("b");
                    hundefueherValueC += $(this).data("c");
                    hundefueherValueD += $(this).data("d");

                    $('.debug').html('Hundefueher Frage: ' + hundefueherQuestionId + ':  A: ' + hundefueherValueA + '  |  B: ' + hundefueherValueB + '  |  C: ' + hundefueherValueC + '  |  D: ' + hundefueherValueD);
                }

                // Hundetyp Fragen
                let hundetypQuestionId = questionID-16;
                if(hundetypQuestionId > 0 && hundetypQuestionId < 15) {
                    hundetypValueA += $(this).data("a");
                    hundetypValueB += $(this).data("b");
                    hundetypValueC += $(this).data("c");
                    hundetypValueD += $(this).data("d");

                    $('.debug2').html('Hundetyp Frage: ' + hundetypQuestionId + ':  A: ' + Math.round( hundetypValueA) + '  |  B: ' + Math.round( hundetypValueB ) + '  |  C: ' + hundetypValueC + '  |  D: ' + hundetypValueD);
                }
                questionID++;

                if(questionID <= questionCount-1) {
                    showNextQuestion();
                }
                else {
                    showResult();
                }
            });
        }

        function showNextQuestion()
        {
            // show section
            $("section").each(function( index )
            {
                $( this ).removeClass( "show" );

                if(index == questionID)
                {
                    $( this ).addClass( "show" );

                    // check timer
                    var hasTimer = $(this).hasClass("hasTimer");
                    if(hasTimer == true)
                    {
                        var timeout1 = setTimeout(function()
                        {
                            $('.progressbar_'+questionID).addClass('animate');

                        }, 200);


                        clearTimeout(timeout);
                        timeout = setTimeout(function()
                        {
                            hideQuestionImage();

                        }, 2200); // 2200
                    }
                }
            });
        }

        function hideQuestionImage()
        {
            $("section").each(function( index )
            {
                if(index == questionID)
                {
                    $(".container_a", $( this )).fadeOut(500);
                    $(".container_b", $( this )).delay(500).fadeIn(500);
                }
            });
        }

        function showQuestionById(id)
        {
            questionID = id;
            showNextQuestion();
        }

        function showResult() {
            $(".question_page").removeClass("show");
            $(".result_page_01").addClass("show");

            // A = oben  |  B = unten  |  C = links  |  d = rechts

            // Result: Hundeführer
            if (hundefueherValueA >= hundefueherValueB) {
                if (hundefueherValueC >= hundefueherValueD) {
                    resultFuehrerString = resultFuehrerArray[0]; // Chef
                    resultFuehrerNumber = 1;
                } else {
                    resultFuehrerString = resultFuehrerArray[1] // Partner
                    resultFuehrerNumber = 2;
                }
            } else {
                if (hundefueherValueC >= hundefueherValueD) {
                    resultFuehrerString = resultFuehrerArray[2] // Trainer
                    resultFuehrerNumber = 3;
                } else {
                    resultFuehrerString = resultFuehrerArray[3] // Förderer
                    resultFuehrerNumber = 4;
                }
            }



            // Result: Hundetyp
            let yValue = Math.round(hundetypValueA) - Math.round(hundetypValueB); // A/B
            let xValue = hundetypValueD - hundetypValueC;  // C/D
            //console.log('A/B: ' + yValue + ' // C/D: ' + xValue);

            // Blau & Grün: typ 1-8
            if (yValue > 0) {

                // Grün: typ 5-8
                if(xValue > 0){

                    // Grün: typ 5-6
                    if(yValue > 3){

                        // Einfarbig Grüner Hund
                        if(xValue > 3){
                            resultTypNumber = 6;
                        }
                        // Grüner Hund mit Blau
                        else {
                            resultTypNumber = 5
                        }
                    }
                    // Grün: typ 7-8
                    else {
                        // Grüner Hund mit Rot
                        if(xValue > 3){
                            resultTypNumber = 8;
                        }
                        // Grüner Bunter Hund
                        else {
                            resultTypNumber = 7;
                        }
                    }
                }
                // Blau: typ 1-4
                else {
                    // typ 1-2
                    if(yValue > 3){
                        // Einfarbig Blauer Hund
                        if(xValue < -3) {
                            resultTypNumber = 1;
                        }
                        // Blauer Hund mit Grün
                        else {
                            resultTypNumber = 2;
                        }
                    }
                    // typ  3-4
                    else {

                        // Blauer Hund mit Gelb
                        if(xValue < -3) {
                            resultTypNumber = 3;
                        }
                        // Blauer Bunter Hund
                        else {
                            resultTypNumber = 4;
                        }
                    }
                }
            }

            // typ Gelb/Rot: typ 9-16
            else {

                // Rot : typ 13-16
                if(xValue > 0) {

                    // typ 15, 16
                    if(yValue < -3){
                        // Einfarbig Roter Hund
                        if(xValue>3) {
                            resultTypNumber = 16;
                        }
                        // Roter Hund mit Gelb
                        else {
                            resultTypNumber = 15;
                        }
                    }
                    // typ 11, 12
                    else {
                        // Roter Hund mit Grün
                        if(xValue>3) {
                            resultTypNumber = 14;
                        }
                        // Roter Bunter Hund
                        else {
                            resultTypNumber = 13;
                        }
                    }
                }

                // Gelb: typ 9-12
                else {
                    // typ 11-12
                    if(yValue < -3) {
                        // Einfarbig Gelber Hund
                        if(xValue < -3) {
                            resultTypNumber = 11;
                        }
                        // Gelber Hund mit Rot
                        else {
                            resultTypNumber = 12;
                        }
                    }
                    // typ 9-10
                    else {
                        // Gelber Hund mit Blau
                        if(xValue < -3) {
                            resultTypNumber = 9;
                        }
                        // Gelber Bunter Hund
                        else {
                            resultTypNumber = 10;
                        }
                    }
                }
            }


            // resultFuehrerNumber: 1-4
            // resultTypNumber 1-16
            $('.debug3').html(resultFuehrerNumber + ": " + resultFuehrerString + ' / ' + resultTypNumber + ": " + resultTypArray[resultTypNumber-1]);
            $('.debug4').html(resultLongVersionPdf[resultTypNumber-1][resultFuehrerNumber-1]);

            $('.result_fuehrer_text').text(resultFuehrerTextArray[resultFuehrerNumber-1]); //
            $('.result_typ_text').text(resultTypTextArray[resultTypNumber-1]);


            $('.answer_pdf').click(function()
            {
                if(vollversion == true)
                {
                    window.open("pdf_longversion/" + resultLongVersionPdf[resultTypNumber-1][resultFuehrerNumber-1], '_blank');
                }
                else {
                    window.open("pdf_shortversion/" + resultShortVersionPdf[resultTypNumber-1][resultFuehrerNumber-1], '_blank');
                }
            });
        }
    });
})();